export const splitArrayToChunks = <Item>(array: Item[], chunkSize: number) => {
  const chunks: Item[][] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export const getUniqueArrayByKey = <Item>(
  array: Item[],
  key: keyof Item
): Item[] => {
  const uniqueArray: Item[] = []
  const uniqueKeys = new Set()
  for (const item of array) {
    if (!uniqueKeys.has(item[key])) {
      uniqueArray.push(item)
      uniqueKeys.add(item[key])
    }
  }
  return uniqueArray
}

export const getUniqueArray = <Item>(array: Item[]): Item[] => {
  return Array.from(new Set(array))
}

export const compareArrays = <Item>(
  first: Item[],
  second: Item[],
  comparator?: (first: Item, second: Item) => boolean
): boolean => {
  if (first.length !== second.length) {
    return false
  }
  return first.every((item, index) => {
    if (comparator) {
      return comparator(item, second[index])
    }
    return item === second[index]
  })
}

export const compareArraysElements = <Item>(
  arr1: Item[],
  arr2: Item[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = [...arr1].sort((a, b) => (a > b ? 1 : -1))
  const sortedArr2 = [...arr2].sort((a, b) => (a > b ? 1 : -1))

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}
