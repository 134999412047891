type RouteDefinition = {
  pathname: string
  meta: { title: string }
}

export const APP_ROUTES = {
  activityOverview: {
    pathname:
      '/users/[userId]/coach-plan-days/[date]/activities/[activityId]/sessions/[sessionId]',
    meta: {
      title: 'app_routes_activity_overview_meta_title',
    },
  },
  activityCalendar: {
    pathname: '/users/[userId]/coach-plan-days/calendar/[currentDate]',
    meta: {
      title: 'app_routes_activity_calendar_meta_title',
    },
  },
  activityCreate: {
    pathname:
      '/users/[userId]/coach-plan-days/[date]/activities/create/[activityType]',
    meta: {
      title: 'app_routes_activity_create_meta_title',
    },
  },
  activityEdit: {
    pathname:
      '/users/[userId]/coach-plan-days/[date]/activities/[activityId]/edit',
    meta: {
      title: 'app_routes_activity_edit_meta_title',
    },
  },
  profileIndex: {
    pathname: '/users/[userId]/profile',
    meta: {
      title: 'app_routes_profile_index_meta_title',
    },
  },
  profileAiHelper: {
    pathname: '/users/[userId]/profile/ai-helper',
    meta: {
      title: 'app_routes_profile_ai_helper_meta_title',
    },
  },
  profileAccount: {
    pathname: '/users/[userId]/profile/account',
    meta: {
      title: 'app_routes_profile_account_meta_title',
    },
  },
  profileClient: {
    pathname: '/users/[userId]/profile/client',
    meta: {
      title: 'app_routes_profile_index_meta_title',
    },
  },
  profileProgress: {
    pathname: '/users/[userId]/profile/progress',
    meta: {
      title: 'app_routes_profile_progress_meta_title',
    },
  },
} satisfies Record<string, RouteDefinition>

type AppRoutesType = keyof typeof APP_ROUTES

/**
 *
 * Returns pathname for specific route
 * @example
 * import { getRoutePathname } from '@/router/routes'
 * const redirectTo = {
 *  pathname: getRoutePathname('activityEdit'),
 *  query: { userId: user.uid },
 * }
 * router.push(redirectTo)
 * @param name The Route Name
 * @returns The Specific route pathname
 */
export function getRoutePathname(name: AppRoutesType): string {
  return APP_ROUTES[name].pathname
}

export const USER_PROFILE_ADMIN_ROUTES = [
  APP_ROUTES.profileAccount,
] satisfies RouteDefinition[]

export const USER_PROFILE_DG_ROUTES = [
  APP_ROUTES.profileProgress,
] satisfies RouteDefinition[]

export const USER_PROFILE_HC_ROUTES = [
  APP_ROUTES.profileAiHelper,
  APP_ROUTES.profileClient,
  APP_ROUTES.profileProgress,
] satisfies RouteDefinition[]
